import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, head, isEmpty, isNil, isUndefined, noop } from 'lodash';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import WorkguideCustomerAware from '../../../Workguide/containers/WorkguideCustomerAware';
import { AuthorizedCustomer } from '../../components/Card/Form/Fields';
import { actions as cardActions } from '../../index';
import { getValidator } from '../../../../globals';
import { getTranslationKey } from '../../../../staticMappings/mapVisecaCodes';
import withWorkguideInstanceData from '../../../Workguide/hocs/withWorkguideInstanceData';
import WithCardSelect from '../../components/Card/Form/WithCardSelect';
import withCustomerCardData from '../../hocs/withCustomerCardData';
import { cardTypeMap } from '../../../../staticMappings/cards';
import { Select } from '../../../General';

const reasonOptions = [{
  label: <FormattedMessage id={getTranslationKey('replacementReasonMap', 'lost_or_stolen')} />,
  value: 'lost_or_stolen'
}, {
  label: <FormattedMessage id={getTranslationKey('replacementReasonMap', 'card_damage')} />,
  value: 'card_damage'
}];

const feeExemptionOptions = [{
  value: true,
  label: <FormattedMessage id="General.Yes" />
}, {
  value: false,
  label: <FormattedMessage id="General.No" />
}];

class Replacement extends React.Component {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    const { cardActions, activity } = this.props;

    cardActions.cardReplacementFormInitRequest({
      formData: get(activity, 'workguideData.formData.formData', get(activity, 'workguideData.formData'))
    });
  }

  /**
   * Handle customer change in instance data
   *
   * @param   {Object}  customer  Customer from /person/customer
   *
   * @return  void
   */
  onCustomerChange(customer) {
    const { cardActions } = this.props;

    cardActions.cardAuthorizationsRequest({ customerId: get(customer, 'id') });
  }

  /**
   * Submit the form
   *
   * @return void
   */
  onSubmit() {
    const {
      cardActions,
      customer,
      intl,
      language,
      session,
      workguide,
      formData,
      redirect
    } = this.props;

    cardActions.cardReplacementFormSaveRequest({
      workguide,
      formData,
      language,
      session,
      customer,
      intl
    });
    redirect();
  }

  /**
   * Validate the current data
   *
   * @return {Object} validations Validation result
   */
  validate() {
    const { formData } = this.props;

    const definition = {
      validations: {
        selected: {
          type: 'array',
          required: true
        },
        authorizedCustomer: {
          type: 'object',
          required: true
        },
        reason: {
          type: 'string',
          required: true
        }
      }
    };

    const validator = getValidator();

    return validator.validate(definition, formData);
  }

  isUidErroneous() {
    const { cardInfo, formData } = this.props;
    const card = head(get(formData, 'selected', []));

    const details = get(cardInfo, `cardDetails.${card?.id}.data`);

    return isUndefined(get(details, 'uid')) || get(card, 'details.uid') instanceof Error;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customer,
      authorizations,
      cardInfo,
      redirect,
      cardActions,
      formData,
      activity
    } = this.props;

    const validations = this.validate();
    const disabled = !isNil(activity);
    const selectedCard = get(formData, 'selected', []);

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
      >
        <WithCardSelect
          onCardSelected={cardActions.cardReplacementFormSetValue}
          selected={selectedCard}
          cardInfo={cardInfo}
          validations={validations}
          customer={customer}
          disabled={disabled}
        />
        {!isEmpty(selectedCard) && this.isUidErroneous() && (
          <Alert
            type="error"
          >
            <AlertTitle>
              <FormattedMessage id="Card.Error.UidError.Title" />
            </AlertTitle>
            <AlertContent>
              <FormattedMessage id="Card.Error.UidError.Description" />
            </AlertContent>
          </Alert>
        )}

        {get(selectedCard, '0.state.id') !== 'cardStatus-6' ? (
          <Alert>
            <AlertTitle>
              <FormattedMessage id="Error.General.Title" />
            </AlertTitle>
            <AlertContent>
              <FormattedMessage id="Card.Field.CardIsNotActive" />
            </AlertContent>
          </Alert>
        ) : (
          <>
            <Row style={{ paddingBottom: '15px' }}>
              <Col lg={12} md={12}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="Card.Field.ReplacementReason" />
                  </Label>

                  <Select
                    id="reason"
                    name="reason"
                    value={get(formData, 'reason')}
                    options={reasonOptions}
                    showValidations
                    validations={validations.reason}
                    onChange={cardActions.cardReplacementFormSetValue}
                    disabled={disabled}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ paddingBottom: '15px' }}>
              <Col lg={12} md={12}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="Card.Field.ReplacementFeeExemption" />
                  </Label>

                  <Select
                    id="feeExemption"
                    name="feeExemption"
                    value={get(formData, 'feeExemption')}
                    options={feeExemptionOptions}
                    onChange={cardActions.cardReplacementFormSetValue}
                    disabled={disabled}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ paddingBottom: '15px' }}>
              <Col lg={12} md={12}>
                <AuthorizedCustomer
                  authorizations={get(authorizations, 'data', [])}
                  value={get(formData, 'authorizedCustomer.id')}
                  validations={validations.authorizedCustomer}
                  onChange={cardActions.cardReplacementFormSetValue}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row style={{ paddingTop: '10px' }}>
              <Col lg={12} md={12}>
                <Button
                  color="primary"
                  onClick={this.onSubmit}
                  disabled={disabled || !isEmpty(validations) || this.isUidErroneous()}
                >
                  <FormattedMessage id="Card.Button.RequestReplacement" />
                </Button>

                <span style={{ paddingLeft: '10px' }} />

                <Button color="primary" outline onClick={redirect}>
                  <FormattedMessage id="Card.Button.Cancel" />
                </Button>
              </Col>
            </Row>
          </>
        )}
      </WorkguideCustomerAware>
    );
  }
}

Replacement.propTypes = {
  authorizations: PropTypes.object,
  cardActions: PropTypes.object.isRequired,
  customer: PropTypes.object,
  formData: PropTypes.object,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string,
  redirect: PropTypes.func,
  session: PropTypes.object.isRequired,
  activity: PropTypes.object,
  cardInfo: PropTypes.object,
  workguide: PropTypes.object
};

Replacement.defaultProps = {
  authorizations: {},
  customer: undefined,
  language: 'de',
  redirect: noop,
  cardInfo: {},
  workguide: undefined,
  formData: {},
  activity: undefined
};

function mapStateToProps(state) {
  const { card, login } = state;

  return {
    language: login.language,
    session: login.session,
    replacement: card.replacement,
    authorizations: card.authorizations,
    formData: card.cardReplacementForm.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cardActions: bindActionCreators(cardActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  // fetch customer cards with described details
  withCustomerCardData({ details: ['status'], allowedCardTypes: cardTypeMap.get('credit') })(
    connect(mapStateToProps, mapDispatchToProps)(
      injectIntl(Replacement)
    )

  )
);
