import { takeLatest } from 'redux-saga/effects';
import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import actions from '../actions/Actions';
import getBirthdays from './getBirthdays';
import getConsultantInsights from './ConsultantInsights';
import getCustomerGroups from './getCustomerGroups';
import getCustomerIdByCustomerNumber from './getCustomerIdByCustomerNumber';
import getCustomerSegments from './getCustomerSegments';
import getInvestmentMaturities from './getInvestmentMaturities';
import getKpi from './getKpi';
import getMbo from './getMbo';
import getOutlookCalendar from './getOutlookCalendar';
import getOutlookCalendarAndBirthdays from './getOutlookCalendarAndBirthdays';

const {
  BIRTHDAYS_REQUEST,
  CUSTOMER_GROUPS_REQUEST,
  CUSTOMER_ID_BY_CUSTOMER_NUMBER_REQUEST,
  CUSTOMER_SEGMENTS_REQUEST,
  FETCH_CONSULTANT_INSIGHTS_REQUEST,
  INVESTMENT_MATURITIES_REQUEST,
  KPI_REQUEST,
  MBO_REQUEST,
  OUTLOOK_CALENDAR_AND_BIRTHDAYS_REQUEST,
  OUTLOOK_CALENDAR_REQUEST
} = actions;

export default function* DashboardRootSaga() {
  yield takeLatest(BIRTHDAYS_REQUEST, getBirthdays);
  yield takeLatest(CUSTOMER_GROUPS_REQUEST, getCustomerGroups);
  yield takeLatest(CUSTOMER_SEGMENTS_REQUEST, getCustomerSegments);
  yield takeLatest(FETCH_CONSULTANT_INSIGHTS_REQUEST, getConsultantInsights);
  yield takeLatest(INVESTMENT_MATURITIES_REQUEST, getInvestmentMaturities);
  yield takeLatest(KPI_REQUEST, getKpi);
  yield takeLatest(MBO_REQUEST, getMbo);
  yield takeLatest(OUTLOOK_CALENDAR_AND_BIRTHDAYS_REQUEST, getOutlookCalendarAndBirthdays);
  yield takeLatest(OUTLOOK_CALENDAR_REQUEST, getOutlookCalendar);
  yield takeLatestByDataKey(CUSTOMER_ID_BY_CUSTOMER_NUMBER_REQUEST, getCustomerIdByCustomerNumber);
}
