import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import legalHoldContractAction from '../../actions/Contract/LegalHoldContract';

const {
  GET_LEGAL_HOLD_CONTRACT_PENDING,
  GET_LEGAL_HOLD_CONTRACT_FULFILLED,
  GET_LEGAL_HOLD_CONTRACT_REJECTED
} = legalHoldContractAction;

export default function* fetchLegalHoldContract(request) {
  const { dataKey } = request;

  yield put({ type: GET_LEGAL_HOLD_CONTRACT_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .and()
      .eq('customer.id', dataKey, { type: 'string' })
      .eq('contractType.id', 'contractType-6442')
      .eq('contractStatus.id', 'contractStatus-90')
      .end()
      .select(['contractType.id'])
      .getQueryString();

    const url = `/entity/contract/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: GET_LEGAL_HOLD_CONTRACT_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: GET_LEGAL_HOLD_CONTRACT_REJECTED, dataKey, error });

    return error;
  }
}
