import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get } from 'lodash';

import investmentMaturitiesAction from '../actions/InvestmentMaturities';

const {
  INVESTMENT_MATURITIES_PENDING,
  INVESTMENT_MATURITIES_FULFILLED,
  INVESTMENT_MATURITIES_REJECTED
} = investmentMaturitiesAction;

export default function* getInvestmentMaturities({ dataKey }) {
  yield put({ type: INVESTMENT_MATURITIES_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('consultantId', dataKey, { type: 'string' })
      .getQueryString();

    const url = `/investment/maturities/${query}`;
    const data = yield call(handleNextHeaders, url);
    const payload = get(data, 0);

    yield put({ type: INVESTMENT_MATURITIES_FULFILLED, payload, dataKey });

    return payload;
  } catch (error) {
    yield put({ type: INVESTMENT_MATURITIES_REJECTED, error, dataKey });

    return error;
  }
}
