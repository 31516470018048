import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { compact, get, isEmpty, isEqual, isNil, noop } from 'lodash';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import { WorkguideCustomerAware } from '../../../Workguide';
import provisionActions from '../../actions/Actions';
import { getValidator } from '../../../../globals';
import { singlePartnerFormalitiesFulfilled } from '../../../BpfSecuritiesAccount/lib/Utils';
import { isCompany, isDoublePartner } from '../../../../lib/Customer/Utils';
import Formalities from '../../../BpfCms/containers/Workguide/Component/Formalities';
import AccountSelect from '../AccountSelect/AccountSelect';
import BvgClassification from '../../components/BvgClassification/BvgClassification';
import DepotOpening from '../DepotOpening/DepotOpening';
import hasProvisionAccounWithdrawalLimit from '../../lib/hasProvisionAccountWithdrawalLimit';
import getWithdrawalValidFormToValidations from '../../lib/getWithdrawalValidFormToValidations';

function getExecutionStartValidations(form) {
  const { data } = form;

  if (get(data, 'selectedAccount.accountKey') !== 0) {
    if (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
      const isAfterToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isAfter(moment());
      const minDate = isAfterToday ? moment(get(data, 'validFrom')) : moment();
      return {
        isValidDate: [],
        minDate: [minDate]
      };
    }

    const startDate = moment().add(31, 'days');
    return {
      isValidDate: [],
      minDate: [startDate]
    };
  }

  return {
    isValidDate: [],
    minDate: [moment()]
  };
}

class OpenPrivorRetirementSavingsAccountDepositForm extends PureComponent {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeAccount = this.onChangeAccount.bind(this);
    this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
  }

  componentWillUnmount() {
    const { provisionActions } = this.props;
    provisionActions.openPrivorRetirementSavingsAccountDepositFormReset();
  }

  /**
   * Event handler for when the customer changes.
   * Triggers an action to request provision accounts.
   */
  onCustomerChange() {
    const { provisionActions, customer } = this.props;
    const customerId = get(customer, 'id');
    provisionActions.provisionAccountsRequest({ dataKey: customerId });
    provisionActions.openPrivorRetirementSavingsAccountDepositFormInitRequest({ customer });
  }

  onSubmit() {
    const {
      provisionActions, form, customer, consultant, toggleRedirectModal, qiDeclaration
    } = this.props;
    const customerId = get(customer, 'id');
    const consultantId = get(consultant, 'id');

    provisionActions.openPrivorRetirementSavingsAccountDepositFormSaveRequest({ data: get(form, 'data'), customerId, consultantId, qiDeclaration });
    toggleRedirectModal();
  }

  onChangeAccount(id, value) {
    const { form, provisionActions } = this.props;

    const updatedData = {
      ...get(form, 'data'),
      selectedAccount: value
    };

    // resets form values and set account in form data
    provisionActions.openPrivorRetirementSavingsAccountDepositFormInitRequest({ data: updatedData });
  }

  /**
   * Validate the form data
   *
   * @return {Object} validations Validation result
   */
  validate() {
    const { form, qiDeclarationFulfilled, qiDeclaration } = this.props;

    const validator = getValidator();

    const validFromAndUntilValidation = getWithdrawalValidFormToValidations(form);
    const executionStartValidations = getExecutionStartValidations(form);

    let qiCustomDocContentValidation = {};

    let definition = {
      validations: {
        // Account Select
        selectedAccount: {
          type: 'object',
          required: true
        },

        // BVG Classification
        occupationalPension: {
          type: 'boolean',
          required: get(form, 'data.selectedAccount.accountKey') === 0
        },
        // documentChangeBeneficiaryDesired: {
        //   type: 'boolean',
        //   required: get(form, 'data.selectedAccount') === 0
        // },

        // Depot Opening
        validFrom: {
          type: 'string',
          required: !get(form, 'data.securitiesSolution', false) && get(form, 'data.securitiesSolutionCancellation', false),
          validations: get(validFromAndUntilValidation, 'validFrom', {})
        },
        validUntil: {
          type: 'string',
          required: !get(form, 'data.securitiesSolution', false) && get(form, 'data.securitiesSolutionCancellation', false),
          validations: get(validFromAndUntilValidation, 'validUntil', {})
        }
      }
    };

    if (qiDeclarationFulfilled && isNil(get(qiDeclaration, 'data.resultVariables.contract'))) {
      qiCustomDocContentValidation = {
        qiCustomDocContent: {
          type: 'object',
          required: true,
          validations: {
            dbaLandCh: {
              type: 'boolean',
              required: true
            },
            dbaLandOther: {
              type: 'object',
              required: !get(form, 'data.qiCustomDocContent.dbaLandCh', false)
            }
          }
        },
      };
    }

    // Fund and execution spezific validations
    if (get(form, 'data.securitiesSolution', false)) {
      const fundValidations = {
        // Anlage Infos & Fond Selektion aber nur wenn "get(form, 'data.securitiesSolution', false)" auf true ist
        // Funds
        investmentObjective: {
          type: 'number',
          required: true
        },
        fundsTotal: {
          type: 'number',
          required: true,
          validations: {
            isGe: [100],
            isLe: [100]
          }
        },
      };

      const executionValidations = {
        executionType: {
          type: 'string',
          required: true
        },
        amount: {
          type: 'number',
          required: get(form, 'data.executionType') !== 'standingOrderBalance',
          validations: {
            isGe: [100],
          }
        },
        executionStartDate: {
          type: 'string',
          required: true,
          validations: executionStartValidations
        },
        executionEndDate: {
          type: 'string',
          required: false,
          validations: {
            isValidDate: [],
          }
        },
        periodicity: {
          type: 'number',
          required: get(form, 'data.executionType') === 'standingOrderAmount'
        }
      };

      definition = {
        validations: {
          ...get(definition, 'validations', {}),
          ...qiCustomDocContentValidation,
          ...fundValidations,
          ...executionValidations
        }
      };
    }

    return validator.validate(definition, get(form, 'data', {}));
  }

  isBusinessOrPartnerCustomer() {
    const { customer } = this.props;
    return isDoublePartner(customer) || isCompany(customer);
  }

  isCustomerToOld() {
    const { customer } = this.props;
    return get(customer, 'age', 0) >= 70;
  }

  isSubmitDisabled(validations) {
    const {
      provisionAccountErrors, provisionAccountsFullfiled, errors, form
    } = this.props;
    return !isEmpty(validations) // validation is not valid
      || (!isEqual(provisionAccountErrors, false) // provisionAccounts load has error
      || !provisionAccountsFullfiled) // provisionAccounts could not be loaded
      || !isEmpty(errors) // has errors
      || (get(form, 'data.selectedAccount.accountKey') !== 0 && !get(form, 'data.securitiesSolution', false));
  }

  hasCustomerNoSegment() {
    const { customer } = this.props;
    const customerSegmentId = get(customer, 'segment.id');
    return isNil(customerSegmentId) || customerSegmentId === 'customerSegment-80';
  }

  render() {
    const {
      customer,
      form,
      errors,
      provisionAccountsFullfiled,
      provisionActions,
      language,
      redirect
    } = this.props;
    const data = get(form, 'data', {});
    const validations = this.validate();

    const isBusinessOrPartnerCustomer = this.isBusinessOrPartnerCustomer();
    const hasCustomerNoSegment = this.hasCustomerNoSegment();
    const isCustomerToOld = this.isCustomerToOld();

    if (!isEmpty(errors)) {
      return (
        <Alert type="error">
          <AlertTitle>
            <FormattedMessage id="Error.General.Title" />
          </AlertTitle>
          <AlertContent>
            <FormattedMessage id="General.Error.Unknown" />
          </AlertContent>
        </Alert>
      );
    }

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
      >
        {
          isBusinessOrPartnerCustomer && (
            <Alert type="warning">
              <AlertContent>
                <FormattedMessage id="Provision.Form.DepotOpening.Error.OnlySinglePartner" />
              </AlertContent>
            </Alert>
          )
        }
        {
          isCustomerToOld && (
            <Alert type="warning">
              <AlertContent>
                <FormattedMessage id="Provision.Form.DepotOpening.Error.CustomerToOld" />
              </AlertContent>
            </Alert>
          )
        }
        {
          hasCustomerNoSegment && (
            <Alert type="warning">
              <AlertTitle>
                <FormattedMessage id="Provision.Form.DepotOpening.Error.Title" />
              </AlertTitle>
              <AlertContent>
                <FormattedMessage id="Provision.Form.DepotOpening.Error.NoSegment" />
              </AlertContent>
            </Alert>
          )
        }

        {
          !isBusinessOrPartnerCustomer && !hasCustomerNoSegment && !isCustomerToOld && (
            <>
              <Row style={{ paddingTop: '1em', paddingBottom: '1em' }}>
                <Col lg={12} md={12} sm={12}>
                  <Formalities customer={customer} />
                </Col>
              </Row>

              <AccountSelect
                customer={customer}
                validations={validations}
                selectedAccount={get(data, 'selectedAccount')}
                onChange={this.onChangeAccount}
                withNewAccount
              />
              {
                // only active if selected account is a new account
                get(data, 'selectedAccount.accountKey') === 0 && provisionAccountsFullfiled
                && (
                  <BvgClassification
                    customer={customer}
                    validations={validations}
                    data={data}
                    onChange={provisionActions.openPrivorRetirementSavingsAccountDepositFormSetValue}
                  />
                )
              }
              <DepotOpening
                customer={customer}
                validations={validations}
                data={data}
                onChange={provisionActions.openPrivorRetirementSavingsAccountDepositFormSetValue}
                language={language}
              />
            </>
          )
        }

        <div className="mt-5">
          <Button
            color="primary"
            disabled={this.isSubmitDisabled(validations) || hasCustomerNoSegment || isBusinessOrPartnerCustomer}
            onClick={this.onSubmit}
          >
            <FormattedMessage id="Provision.Form.DepotOpening.SubmitButton" />
          </Button>
          <span style={{ paddingRight: '20px' }} />
          <Button color="primary" outline onClick={() => redirect()}>
            <FormattedMessage id="General.Cancel" />
          </Button>
        </div>

      </WorkguideCustomerAware>
    );
  }
}

OpenPrivorRetirementSavingsAccountDepositForm.propTypes = {
  consultant: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  errors: PropTypes.array,
  form: PropTypes.object.isRequired,
  formalitiesFulfilled: PropTypes.bool,
  formalitiesRequesting: PropTypes.bool,
  language: PropTypes.string,
  provisionActions: PropTypes.object.isRequired,
  provisionAccountErrors: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  provisionAccountsFullfiled: PropTypes.bool,
  qiDeclaration: PropTypes.object,
  qiDeclarationFulfilled: PropTypes.bool,
  redirect: PropTypes.func,
  toggleRedirectModal: PropTypes.func
};
OpenPrivorRetirementSavingsAccountDepositForm.defaultProps = {
  errors: [],
  formalitiesFulfilled: false,
  formalitiesRequesting: false,
  language: 'de',
  provisionAccountErrors: false,
  provisionAccountsFullfiled: false,
  qiDeclaration: {},
  qiDeclarationFulfilled: false,
  redirect: noop,
  toggleRedirectModal: noop
};

/**
 * Check if some formalities are requesting
 *
 * @param  {Object} state Application state
 *
 * @return {Boolean}
 */
function formalitiesRequesting(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return (get(state, `bpfCms.customer.${customerId}.requesting`, false));
}

/**
 * Check if formalities for the given customer (and its partners) are fulfilled
 *
 * @param  {Object} state Application state
 *
 * @return {Boolean}
 */
function formalitiesFulfilled(state, ownProps) {
  const { customer } = ownProps;
  const customerId = get(customer, 'id');

  const formalitiesCheck = singlePartnerFormalitiesFulfilled;

  const formalities = get(state, `bpfCms.customer.${customerId}.data`, {});

  return formalitiesCheck({ formalities });
}

function collectErrors(state) {
  return compact([
    get(state, 'provision.openPrivorRetirementSavingsAccountDepositForm.error')
  ]);
}

function mapStateToProps(state, ownProps) {
  const customer = get(ownProps, 'customer');
  const customerId = get(customer, 'id');

  return {
    consultant: state.login.session,
    customer,
    errors: collectErrors(state, customerId),
    form: state.provision.openPrivorRetirementSavingsAccountDepositForm,
    formalitiesFulfilled: formalitiesFulfilled(state, ownProps),
    formalitiesRequesting: formalitiesRequesting(state, ownProps),
    language: state.login.language,
    provisionAccountErrors: get(state, `provision.provisionAccounts.${customerId}.error`),
    provisionAccountsFullfiled: get(state, `provision.provisionAccounts.${customerId}.fulfilled`),
    qiDeclaration: get(state, `securitiesAccount.qiDeclaration.${get(customer, 'id')}`),
    qiDeclarationFulfilled: get(state, `securitiesAccount.qiDeclaration.${get(customer, 'id')}.fulfilled`),
  };
}
function mapDispatchToProps(dispatch) {
  return { provisionActions: bindActionCreators(provisionActions, dispatch) };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(
    OpenPrivorRetirementSavingsAccountDepositForm
  )
);
