import { Reducers } from '@evoja-web/redaction';

import { reducer as accountRubricChangeReducer } from './Portfolio/AccountRubricChange';
import { reducer as accountRubricDeleteReducer } from './Portfolio/AccountRubricDelete';
import { reducer as antimoneyLaunderingReducer } from './AntimoneyLaundering/AntimoneyLaundering';
import { reducer as foreignAssetDeleteReducer } from './ForeignAsset/Delete';
import { reducer as foreignAssetsReducer } from './ForeignAsset/ForeignAssets';
import { reducer as incomesReducer } from './Income/Incomes';
import { reducer as salariesReducer } from './Salary/Salaries';
import { reducer as kpiReducer } from './Kpi/Kpi';
import { reducer as kpiHistoryReducer } from './Kpi/KpiHistory';
import { reducer as kubiRatioReducer } from './Kpi/KubiRatio';
import { reducer as paymentRuleDeleteAction } from './PaymentRule/Delete';
import { reducer as paymentRulesReducer } from './PaymentRule/PaymentRules';
import { reducer as portfoliosReducer } from './Portfolio/Portfolios';
import { reducer as provisinoNariloReducer } from './Portfolio/ProvisionNarilo';
import { reducer as profileCompletionCompanyReducer } from './ProfileCompletion/Company';
import { reducer as ratingsReducer } from './Rating/Ratings';
import { reducer as suspensionSummaryReducer } from './Suspension/Summary';
import { reducer as tariffRulesReducer } from './Tariff/TariffRule';

import { reducer as accountsReducer } from './SetAdvisor/Accounts';
import { reducer as contractsReducer } from './SetAdvisor/Contracts';
import { reducer as customerBalanceReducer } from './SetAdvisor/CustomerBalance';
import { reducer as pricingReducer } from './SetAdvisor/Pricing';
import { reducer as productbundleBundleReducer } from './SetAdvisor/ProductbundleBundle';
import { reducer as productbundleProductReducer } from './SetAdvisor/ProductbundleProduct';
import { reducer as productbundleUsageReducer } from './SetAdvisor/ProductbundleUsage';
import { reducer as productbundleUsageDetailReducer } from './SetAdvisor/ProductbundleUsageDetail';
import { reducer as textblockReducer } from './SetAdvisor/Textblock';
import { reducer as advertisingReducer } from './Advertising/Advertising';
import { reducer as duplicateCheckReducer } from './DuplicateCheck/DuplicateCheck';
import { reducer as db2HistoryReducer } from './Db2/Db2History';
import { reducer as kpiFinancingHistoryReducer } from './Kpi/KpiFinancingHistory';
import { reducer as legalHoldContractReducer } from './Contract/LegalHoldContract';

export const reducers = Reducers([
  antimoneyLaunderingReducer,
  accountRubricChangeReducer,
  accountRubricDeleteReducer,
  db2HistoryReducer,
  duplicateCheckReducer,
  foreignAssetDeleteReducer,
  foreignAssetsReducer,
  incomesReducer,
  salariesReducer,
  kpiReducer,
  kpiHistoryReducer,
  kubiRatioReducer,
  legalHoldContractReducer,
  paymentRuleDeleteAction,
  paymentRulesReducer,
  portfoliosReducer,
  profileCompletionCompanyReducer,
  ratingsReducer,
  suspensionSummaryReducer,
  accountsReducer,
  contractsReducer,
  customerBalanceReducer,
  pricingReducer,
  productbundleBundleReducer,
  productbundleProductReducer,
  productbundleUsageReducer,
  productbundleUsageDetailReducer,
  textblockReducer,
  provisinoNariloReducer,
  advertisingReducer,
  tariffRulesReducer,
  kpiFinancingHistoryReducer
]);

export default reducers.get();
