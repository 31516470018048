import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';
import { get, noop } from 'lodash';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { DateField, NumberField, Select } from '../../../../General';
import StandingOrderAlert from '../../Alerts/StandingOrderAlert';
import hasProvisionAccounWithdrawalLimit from '../../../lib/hasProvisionAccountWithdrawalLimit';

function getExecutionStartMinDate(data) {
  if (get(data, 'selectedAccount.accountKey') !== 0) {
    if (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
      const isAfterToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isAfter(moment());
      const minDate = isAfterToday ? moment(get(data, 'validFrom')) : moment();
      return minDate;
    }

    const startDate = moment().add(31, 'days');
    return startDate;
  }

  return moment();
}

function getExecutionTypeOptions(intl) {
  return [
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.Execution.StandingOrder.FundSavingsPlan.Amount' }), value: 'standingOrderAmount' },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.Execution.StandingOrder.FundSavingsPlan.Balance' }), value: 'standingOrderBalance' }
  ];
}

function getIntervalOptions(intl) {
  return [
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.Execution.StandingOrder.IntervalElements.Weekly' }), value: 1 },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.Execution.StandingOrder.IntervalElements.Monthly' }), value: 2 },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.Execution.StandingOrder.IntervalElements.Quarterly' }), value: 3 },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.Execution.StandingOrder.IntervalElements.HalfYearly' }), value: 4 },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.Execution.StandingOrder.IntervalElements.Yearly' }), value: 5 },
  ];
}

class StandingOrder extends PureComponent {
  renderStandingOrderAmount() {
    const {
      intl, data, validations, onChange, language
    } = this.props;

    return (
      <>
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.AmountInChf" />
          </Col>
          <Col md={9}>
            <NumberField
              id="amount"
              value={get(data, 'amount')}
              onChange={onChange}
              showValidations
              validations={get(validations, 'amount')}
            />
          </Col>
        </Row>
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.FirstTimeExecution" />
          </Col>
          <Col md={9}>
            <DateField
              id="executionStartDate"
              name="executionStartDate"
              value={get(data, 'executionStartDate')}
              minDate={getExecutionStartMinDate(data)}
              onChange={onChange}
              onInputChange={onChange}
              showValidations
              validations={get(validations, 'executionStartDate')}
              language={language}
            />
          </Col>
        </Row>
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Interval" />
          </Col>
          <Col md={9}>
            <Select
              id="periodicity"
              options={getIntervalOptions(intl)}
              value={get(data, 'periodicity')}
              onChange={onChange}
              validations={get(validations, 'periodicity')}
              language={language}
              showValidations
            />
          </Col>
        </Row>
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Enddate" />
          </Col>
          <Col md={9}>
            <DateField
              id="executionEndDate"
              name="executionEndDate"
              value={get(data, 'executionEndDate')}
              minDate={!hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount')) ? moment().add('d', 31) : moment()}
              onChange={onChange}
              onInputChange={onChange}
              showValidations
              language={language}
              validations={get(validations, 'executionEndDate')}
            />
          </Col>
        </Row>
      </>
    );
  }

  renderStandingOrderBalance() {
    const {
      data, validations, onChange, language
    } = this.props;

    return (
      <>
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.ExecuteOn" />
          </Col>
          <Col md={9}>
            <DateField
              id="executionStartDate"
              name="executionStartDate"
              value={get(data, 'executionStartDate')}
              minDate={moment()}
              onChange={onChange}
              onInputChange={onChange}
              language={language}
              showValidations
              validations={get(validations, 'executionStartDate')}
            />
          </Col>
        </Row>
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Enddate" />
          </Col>
          <Col md={9}>
            <DateField
              id="executionEndDate"
              name="executionEndDate"
              value={get(data, 'executionEndDate')}
              minDate={get(data, 'selectedAccount.noticeNecessary', false) ? moment().add('d', 31) : moment()}
              onChange={onChange}
              onInputChange={onChange}
              showValidations
              validations={get(validations, 'executionEndDate')}
              language={language}
            />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      intl, data, onChange, validations, showValidations
    } = this.props;

    return (
      <>
        <Row className="provision-depot-opening--space--top">
          <StandingOrderAlert />
        </Row>
        <Row className="provision-depot-opening--space--top-large">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.FundSavingsPlan.Title" />
          </Col>
          <Col md={9}>
            <Select
              id="executionType"
              options={getExecutionTypeOptions(intl)}
              value={get(data, 'executionType')}
              onChange={onChange}
              validations={get(validations, 'executionType')}
              showValidations={showValidations}
            />
          </Col>
        </Row>
        {get(data, 'executionType') === 'standingOrderAmount' && this.renderStandingOrderAmount()}
        {get(data, 'executionType') === 'standingOrderBalance' && this.renderStandingOrderBalance()}
      </>
    );
  }
}

StandingOrder.propTypes = {
  validations: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func,
  showValidations: PropTypes.bool,
  language: PropTypes.string
};

StandingOrder.defaultProps = {
  onChange: noop,
  data: {},
  showValidations: true,
  language: 'de'
};

export default injectIntl(StandingOrder);
